import React from "react";
import { Container, Row } from "react-bootstrap";
import GatsbyImage from "gatsby-image";
import CatalogoPromos from "../../components/layouts/CatalogoPromos";

import Layout from "../../components/layouts/layout";
import SEO from "../../components/seo";

export default function Promociones(props){

    const parameters = props.location.search;

    return(
        <Layout selectedMenuItem={"promo-cars"} parameters={parameters}>
            <SEO
                title="Grupo Rivero - Agencia Chevrolet Monterrey - Promociones Chevrolet"
                description="Grupo Rivero - Agencia Chevrolet Monterrey - Promociones Chevrolet Autos Nuevos - Seminuevos - Autos Meses sin intereses - Semunuevos Monterrey - Seminuevos Agencia"
                keywords="Agencia Chevrolet"
            />
            <Container>

                <Row>
                    <h1 className="mt-3" style={{ fontSize: 18, color: "#a2a2a2" }}>
                        Promociones agencia Chevrolet autos nuevos, Buick, Cadillac, GMC
                    </h1>
                </Row>

                <CatalogoPromos promoType="nuevos" props={props}/>

                <Row className="pt-3 pb-3">
                    <h2 style={{ fontSize: 12 }}>
                        Aprovecha las promociones que tenemos en nuestra Agencia Chevrolet
                    </h2>
                </Row>
            </Container>
        </Layout>
    )

}