import React, { useState, useRef, useCallback } from "react";
import { Tabs, Tab } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

import WhatsApp from "../../../images/icons/whatsapp-alt-0.png";
import Llamada from "../../../images/icons/phone-alt-0.png";
import Mail from "../../../images/icons/mail-alt-0.png";

export default function FormularioVentaSalesforce({ children, pagina, btnTitulo, props, infoCar, infoCotiza, pagoMensual, sucursalId, paramsURL, selectPromo }) {
    console.log("InfoCar Formulario: ", infoCar, "selectPromo", selectPromo);
    const [validationToken, setValidationToken] = useState("");
    const [selected, setSelected] = useState("WhatsApp");
    const [whatsapp, setWhatsApp] = useState("");
    const recaptchaRef = useRef();

    var variableCid = "";
    var variableUcid = "";

    if(paramsURL?.cid){
        var paramsCid = paramsURL?.cid?.split("cid=");
        variableCid = paramsCid[1];
    } else if (paramsURL?.ucid){
        var paramsUCid = paramsURL?.ucid?.split("ucid=");
        variableUcid = paramsUCid[1];
    }
       // console.log("Tengo params CID: ", variableCid);
       // console.log("Tengo params CID: ", variableUcid);
    function handleKeyPress(event) {
        setWhatsApp(event.target.value);
    }

    const handleVerify = useCallback(
        (token) => {
            setValidationToken(token);
        },
        [setValidationToken]
    );

    function verifyToken(event) {
        if (validationToken === "" || validationToken === null) {
            console.log("ESTA VACIO EL CAPTCH");
            alert("Error en el captcha");
            return;
        }
        event.target.submit();
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        verifyToken(event);
    };

    return (
        <>
            {/* <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST"> */}
            <form onSubmit={handleSubmit} action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" >

                <input type="hidden"
                    id="g-recaptcha-response"
                    name="g-recaptcha-response"
                    value={validationToken}></input>

                <div className="form-group" >
                    <input hidden id="00Nf400000UBhZ6" name="00Nf400000UBhZ6" value={paramsURL?.ucid ?  paramsUCid[1] : "gruporivero.com" } /> {/** udid */} 
                    <input hidden id="Campaign_ID" name="Campaign_ID" value={paramsURL?.cid ? paramsCid[1] : "7012S000000Gj6pQAC" } /> {/** cid  7012S000001F83xQAC */}
                    <input hidden id="GRI_Campana__c" name="GRI_Campana__c" value={paramsURL?.cid ? paramsCid[1] : "7012S000000Gj6pQAC" } /> {/** cid */}

                    <input hidden id="pagina" name="pagina" value={pagina} />
                    <input hidden id="oid" name="oid" value="00Df4000004ls8N" />
                    <input hidden id="lead_source" name="lead_source" value="Web" />
                    <input hidden id="00Nf400000UBhZw" name="00Nf400000UBhZw" value="Venta de Auto" />

                    <input hidden id="last_name" name="last_name" value="-" />
                    <input hidden id="00N0t000000kw0B" name="00N0t000000kw0B" value={infoCar?.make ? infoCar?.make.toLowerCase() : null} />
                    <input hidden id="00Nf400000UBhZz" name="00Nf400000UBhZz" value={infoCar?.model ? infoCar?.model.toLowerCase() : null} />
                    <input hidden id="00Nf400000UByLY" name="00Nf400000UByLY" value={infoCar?.year ? infoCar?.year : null} />
                    <input hidden id="00Nf400000UBhZx" name="00Nf400000UBhZx" value={infoCar?.fullauto ? infoCar?.fullauto : null} />
                    <input hidden id="00N2S000007ThUK" name="00N2S000007ThUK" value={`https://www.gruporivero.com/${pagina}`} />

                    <input hidden name="retURL" value={`https://gruporivero.com/gracias-por-contactarnos/` + pagina } />
                </div>

                {children}

                {
                    sucursalId ? "" : (
                        <div className="form-group pb-2">
                            <label className="pb-2" htmlFor="name">¿Agencia más cercana?</label>
                            <select
                                id="00Nf400000UBhYt"
                                className="form-control"
                                name="00Nf400000UBhYt"
                                title="Agencia"
                            >
                                {infoCar?.make === "CHEVROLET" || selectPromo?.make === "CHEVROLET" ? (
                                    <>
                                        <option value="1043193">Linda Vista</option>
                                        <option value="232812511">Guadalupe</option>
                                        <option value="382179229">Humberto Lobo</option>
                                        <option value="402145072">Santa Catarina</option>
                                    </>
                                ) : (
                                    <>
                                        <option value="141341484">Humberto Lobo Alianza</option>
                                        <option value="115281495">Gómez Morín</option>
                                    </>
                                )}
                            </select>
                        </div>
                    )
                }

                <div className="form-group pb-2">
                    <label className="pb-2" htmlFor="name">Nombre</label>
                    <input
                        required
                        className="form-control pt-2"
                        id="first_name"
                        maxLength={50}
                        name="first_name"
                        size="20"
                        type="text"
                        placeholder="Nombre"
                    />
                </div>

                <div className="form-group pb-2">
                    <label className="pb-2" htmlFor="contact-value">¿Cómo deseas que te contactemos?</label>

                    <Tabs id="controlled-tab-example" activeKey={selected} onSelect={(k) => setSelected(k)} className="nav-fill m-0" >
                        <Tab eventKey="WhatsApp" title={
                            <div className="d-flex align-items-center justify-content-center" onClick={() => setSelected("WhatsApp")}>
                                <img src={WhatsApp} className="mr-1" style={{ width: 20 }} alt="WhatsApp chevrolet rivero linda vista" />
                                <span className={`text-primary ml-1 ${selected === 'WhatsApp' ? `` : `d-none`}`}>WhatsApp</span>
                            </div>
                        }>
                            <div className="rounded-bottom bg-white w-100 p-3" style={{ border: "1px solid #dee2e6", borderTop: 0 }}>
                                <input
                                    id="contact-value"
                                    type="tel"
                                    className="form-control"
                                    placeholder="WhatsApp a 10 dígitos"
                                    onKeyUp={(e) => handleKeyPress(e)}
                                    pattern="[0-9]{10}"
                                    required={selected === "WhatsApp"}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="Llamada" title={
                            <div className="d-flex align-items-center justify-content-center" onClick={() => setSelected("Llamada")} >
                                <img src={Llamada} className="mr-1" style={{ width: 20 }} alt="telefono chevrolet rivero linda vista" />
                                <span className={`text-primary ml-1 ${selected === 'Llamada' ? `` : `d-none`}`}>Llamada</span>
                            </div>
                        }>
                            <div className="rounded-bottom bg-white  w-100 p-3 pb-3" style={{ border: "1px solid #dee2e6", borderTop: 0 }}>
                                <input
                                    id="contact-value"
                                    type="tel"
                                    className="form-control"
                                    placeholder="Teléfono a 10 dígitos"
                                    onKeyUp={(e) => handleKeyPress(e)}
                                    pattern="[0-9]{10}"
                                    required={selected === "Llamada"}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey="Email" title={
                            <div className="d-flex align-items-center justify-content-center" onClick={() => setSelected("Email")}>
                                <img src={Mail} className="mr-1" style={{ width: 20 }} alt="correo chevrolet rivero linda vista" />
                                <span className={`text-primary ml-1 ${selected === 'Email' ? `` : `d-none`}`}>Correo</span>
                            </div>
                        }>
                            <div className="rounded-bottom bg-white  w-100 p-3 pb-3" style={{ border: "1px solid #dee2e6", borderTop: 0 }}>
                                <input
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    id="email"
                                    aria-describedby="emailHelp"
                                    placeholder="ejemplo@gruporivero.com"
                                    onKeyUp={(e) => handleKeyPress(e)}
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                    required={selected === "Email"}
                                />
                            </div>
                        </Tab>
                    </Tabs>

                    <input hidden type="text" className="form-control" name="00Nf400000UBhZl" id="00Nf400000UBhZl" value={selected} onChange={(e) => setSelected(e.target.value)} />
                    <input hidden type="text" className="form-control" name="00Nf400000UBha3" id="00Nf400000UBha3" onChange={(e) => setWhatsApp(e.target.value)} value={whatsapp} />

                </div>

                <div className="form-group pb-2" style={{ color: 'white' }}>
                    <p className="">
                        * Nunca compartiremos tus datos con nadie más.
                    </p>
                </div>
                <div className="form-group mb-3">
                    <center>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6Le57eEaAAAAAFaBiKOIhLvbDBAXFqoJacXuJ4P3"
                            onChange={handleVerify}
                        />
                    </center>
                </div>
                <div className="center text-center mb-2">
                    <input type="submit"
                        id="btn-contactarserv"
                        className="boton boton-primario"
                        style={{ width: '80%' }}
                        value={btnTitulo}
                        onChange={verifyToken}
                    />
                </div>

                <br />

            </form>
        </>
    );
}